import React, { useState } from "react";
import "./footer.scss";

interface FooterProps {
  title: string;
}

export function Footer(props: FooterProps) {
  const [title, setTitle] = useState(props.title);
  return (
    <div className="footer">
      <div className="footer-right">
        <label>{title}</label>
      </div>
    </div>
  );
}
