import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../service/userService";
import "./home.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Button } from "@medm-ui/button";
import { Delete } from "./delete";
import moment from "moment";

function LinkComponent(props: any) {
  let userId: string = props.data.userId;
  let applicationId: string = props.data.applicationId;

  return (
    <>
      <Edit userId={userId} applicationId={applicationId}></Edit>
      <Delete userId={userId} applicationId={applicationId}></Delete>
    </>
  );
}

function Edit(props: { userId: string; applicationId: string }) {
  const navigate = useNavigate();
  const editUser = () => {
    navigate(
      "/update?userId=" + props.userId + "&applicationId=" + props.applicationId
    );
  };
  return <a onClick={editUser}>Edit</a>;
}

export function Home() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userPermissionData, setUserPermissionData] = useState([]);

  const dateFormatter = (value: any) => {
    if (value === null || value === "") {
      return "";
    }
    return moment(value).format("MM/DD/YYYY hh:mm:ss A");
  };

  const dateComparator = (date1: string, date2: string) => {
    if (date1 === "" && date2 === "") {
      return 0;
    }

    if (date1 === "") {
      return -1;
    } else if (date2 === "") {
      return 1;
    }

    let difference = new Date(date1).getTime() - new Date(date2).getTime();

    return difference;
  };

  const containerStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [columnDefs, setColumnDefs] = useState([
    { field: "userId", resizable: true, width: 250 },
    { field: "applicationId", resizable: true, width: 120 },
    {
      field: "startDate",
      resizable: true,
      width: 190,
      comparator: dateComparator,
    },
    {
      field: "endDate",
      resizable: true,
      width: 190,
      comparator: dateComparator,
    },
    { field: "createdBy", resizable: true },
    {
      field: "createdOn",
      resizable: true,
      width: 190,
      comparator: dateComparator,
    },
    { field: "lastUpdatedBy", resizable: true, width: 140, },
    {
      field: "lastUpdatedOn",
      resizable: true,
      width: 140,
      comparator: dateComparator,
    },
    { field: "requestedBy", resizable: true },
    {
      field: "Action",
      resizable: true,
      cellRenderer: "LinkComponent",
      width: 140,
      sortable: false,
      filter: false,
    },
  ]);
  const defaultColDef = {
    sortable: true,
    filter: true,
  };

  // const [openPopUp, setOpenPopUp] = useState(false);
  // const onCancel = () => {
  //   setOpenPopUp(false);
  // };
  // const onDelete = () => {
  //   setOpenPopUp(true);
  // };

  useEffect(() => {
    setIsLoading(true);
    getUsers()
      .then((data) => {
        data.forEach((d: any) => {
          d.startDate = dateFormatter(d.startDate);
          d.endDate = dateFormatter(d.endDate);
          d.createdOn = dateFormatter(d.createdOn);
          d.lastUpdatedOn = dateFormatter(d.lastUpdatedOn);
        });
        setUserPermissionData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  return (
    <>
      {/* {openPopUp && <Modal onCancel={onCancel} onDelete={onDelete}></Modal>} */}
      {isLoading && <div id="loading">Loading...</div>}
      <div className="page-header">
        <div className="left">
          <h3>User Permission Data</h3>
        </div>
        <div className="right">
          <Button
            data-test-id={"CreateNewUser"}
            buttonId={"CreateNewUser"}
            buttonLabel={"Create New User"}
            buttonType={"Primary"}
            showIcon={true}
            disabled={false}
            iconProps={{
              color: "Black",
              size: "20",
              icon: "plus",
            }}
            onButtonClick={() => navigate("/create")}
            buttonSize={"small"}></Button>
        </div>
      </div>
      {userPermissionData.length > 0 && (
        <div style={containerStyle}>
          <div className="ag-theme-alpine" style={{ height: 700 }}>
            <AgGridReact
              defaultColDef={defaultColDef}
              rowData={userPermissionData}
              columnDefs={columnDefs}
              pagination={true}
              paginationAutoPageSize={true}
              components={{
                LinkComponent,
              }}
            ></AgGridReact>
          </div>
        </div>
      )}
    </>
  );
}
