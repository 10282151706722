import { useEffect, useState } from "react";
import { deleteUser } from "../../../service/userService";
import { Notification } from "../../shared/notification";

export function Delete(props: any) {
  const [userDeleted, setUserDeleted] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const showDeletePop = () => {
    const action = window.confirm(
      "Are you sure you want to delete the user: " + props.userId
    );

    if (action) {
      setIsLoading(true);
      deleteUser(props.userId, props.applicationId)
        .then(() => {
          setUserDeleted(true);
          setIsLoading(false);
          window.location.reload();
        })
        .catch((err) => {
          setErrorResponse("User Deletion Failed");
          console.error(err.message);
          setIsLoading(false);
        })
        .finally(() => {
          setTimeout(() => {
            setErrorResponse("");
          }, 1000);
        })
    }
  };

  return (
    <>
      {isLoading && <div id="loading">Loading...</div>}
      {userDeleted && <Notification type="success" message="User Deleted Successfully" />}
      {errorResponse !== "" && <Notification type="danger" message={errorResponse} />}
      <a onClick={showDeletePop}>Delete</a>
    </>
  );
}
