import React, { useState, useEffect, useRef } from "react";
import { Button } from "@medm-ui/button";
import moment from "moment";
import AdminUserInfo from "../../service/adminUserService";
import { getUsers, updateUser } from "../../service/userService";
import { Notification } from "../shared/notification";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./updateuser.scss";

export function UpdateUser(props: any) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const applicationId = searchParams.get("applicationId");

  const [formErrors, setFormErrors] = useState({
    requestedByValid: true,
    accessStartDateTimeValid: true,
    accessEndDateTimeValid: true,
    formValid: false,
    validationError: {
      requestedByError: "",
      accessStartDateTimeError: "",
      accessEndDateTimeError: "",
    },
  });

  const [userInfo, setUserInfo] = useState({
    userEmailId: userId,
    applicationId: applicationId,
    requestedBy: "",
    accessStartDateTime: "",
    accessEndDateTime: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [userFound, setUserFound] = useState(true);

  useEffect(() => loadUserData(userId, applicationId), []);

  const loadUserData = (userEmailId: any, userApplicationId: any) => {
    setIsLoading(true);
    getUsers()
      .then((res) => {
        let isUserFound = res.some((x: any) => {
          if (x.userId === userEmailId &&
            x.applicationId === userApplicationId) {
            setUserInfo({
              userEmailId: x.userId as string,
              applicationId: x.applicationId as string,
              requestedBy: x.requestedBy as string,
              accessStartDateTime: moment(x.startDate).format(
                "yyyy-MM-DDTHH:mm"
              ),
              accessEndDateTime:
                x.endDate === "" ||
                  x.endDate === undefined ||
                  x.endDate === null
                  ? ""
                  : moment(x.endDate).format("yyyy-MM-DDTHH:mm"),
            });
            setIsLoading(false);
            return true;
          }
          return false;
        });

        if (!isUserFound) {
          setUserFound(isUserFound);
          setTimeout(() => {
            navigate("/home");
          }, 1);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    validateField(name, value);
    setUserInfo((state) => ({ ...state, [name]: value }));
  };

  const validateDate = (date: string) => {
    const dateFormat = "yyyy/MM/DD HH:mm:ss";
    return moment(
      moment(date).format("yyyy/MM/DD HH:mm:ss"),
      dateFormat,
      true
    ).isValid();
  };

  const validateField = (fieldName: any, value: any) => {
    var regx = /^([\w'.+-]+)@([\w-]+\.)+([\w]{2,})$/i;
    switch (fieldName) {
      case "requestedBy":
        const requestedByEmailValid = value.match(regx);
        if (requestedByEmailValid === null) {
          formErrors.validationError.requestedByError =
            "Requested By Email ID is either null or not in valid format";
          formErrors.requestedByValid = false;
        } else {
          formErrors.validationError.requestedByError = "";
          formErrors.requestedByValid = true;
        }
        break;

      case "accessStartDateTime":
        if (validateDate(value) && value !== null) {
          if (
            userInfo.accessEndDateTime !== "" &&
            moment.utc(value).format() >=
            moment.utc(userInfo.accessEndDateTime).format()
          ) {
            formErrors.validationError.accessStartDateTimeError =
              "Access Start DateTime can't be greater than or equal the Access End DateTime";
            formErrors.accessStartDateTimeValid = false;
          } else {
            formErrors.validationError.accessStartDateTimeError = "";
            formErrors.validationError.accessEndDateTimeError = "";
            formErrors.accessStartDateTimeValid = true;
            formErrors.accessEndDateTimeValid = true;
          }
        } else {
          formErrors.validationError.accessStartDateTimeError =
            "Access Start DateTime is not valid";
          formErrors.accessStartDateTimeValid = false;
        }
        break;

      case "accessEndDateTime":
        let accessEndDateTimeValid =
          moment.utc(value).format() >
          moment.utc(userInfo.accessStartDateTime).format();

        if (!validateDate(value)) {
          formErrors.validationError.accessEndDateTimeError =
            "Access End DateTime is not valid";
        } else {
          formErrors.validationError.accessEndDateTimeError = "";
        }

        if (value !== null && !accessEndDateTimeValid) {
          formErrors.validationError.accessEndDateTimeError =
            "Access End DateTime can't be less than or equal the Access Start DateTime";
        } else {
          formErrors.validationError.accessEndDateTimeError = "";
          formErrors.validationError.accessStartDateTimeError = "";
          formErrors.accessStartDateTimeValid = true;
        }

        if (formErrors.validationError.accessEndDateTimeError) {
          accessEndDateTimeValid = false;
        }
        formErrors.accessEndDateTimeValid = accessEndDateTimeValid;
        break;
      default:
        break;
    }

    if (
      formErrors.requestedByValid &&
      formErrors.accessStartDateTimeValid &&
      formErrors.accessEndDateTimeValid
    ) {
      formErrors.formValid = true;
    } else {
      formErrors.formValid = false;
    }
  };

  const handleCancel = () => {
    navigate("/home");
  };

  const handleSubmit = async (event: any) => {
    const adminUserInfo = AdminUserInfo();

    if (adminUserInfo) {
      const userData: any = {
        userId: userInfo.userEmailId,
        applicationId: userInfo.applicationId,
        requestedBy: userInfo.requestedBy,
        startDate: moment(userInfo.accessStartDateTime).format(
          "yyyy/MM/DD HH:mm:ss"
        ),
        endDate:
          userInfo.accessEndDateTime !== ""
            ? moment(userInfo.accessEndDateTime).format("yyyy/MM/DD HH:mm:ss")
            : "",
        lastUpdatedBy: adminUserInfo.email,
        lastUpdatedOn: moment.utc().format("yyyy/MM/DD HH:mm:ss"),
      };
      setIsLoading(true);
      updateUser(userData)
        .then(() => {
          setIsLoading(false);
          setUserUpdated(true);
          formErrors.formValid = false;
          setTimeout(() => {
            setUserUpdated(false);
          }, 5000);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error !== null && error !== undefined) {
            if (error.data["error"].errors.length > 0) {
              let errorField = error.data["error"].errors[0].errorField;
              let errorDescription =
                error.data["error"].errors[0].errorDescription;
              setErrorResponse(errorField + " " + errorDescription);
            }
          } else {
            setErrorResponse("Error occurred while updating user");
          }
          setTimeout(() => {
            setErrorResponse("");
          }, 1000);
          console.error(error);
        });
    }
    // event.preventDefault();
  };

  return (
    <div className="update-user">
      {isLoading && <div id="loading">Loading...</div>}
      {userUpdated && <Notification type="success" message="User Updated Successfully" />}
      {errorResponse !== "" && <Notification type="warning" message={errorResponse} />}
      {!userFound && <Notification type="warning" message="User not found" />}

      <div className="page-header">
        <div className="left">
          <h3>Update User</h3>
        </div>
        <div className="right">
          <Button
            buttonId={"Update"}
            buttonLabel={"Update"}
            buttonType={"Primary"}
            disabled={!formErrors.formValid}
            showIcon={true}
            iconProps={{
              color: "Black",
              size: "20",
              icon: "save",
            }}
            onButtonClick={handleSubmit}
            buttonSize={"small"}></Button>
          <Button
            buttonId={"Cancel"}
            buttonLabel={"Cancel"}
            buttonType={"Secondary"}
            disabled={false}
            showIcon={true}
            iconProps={{
              color: "Black",
              size: "20",
              icon: "close",
            }}
            onButtonClick={handleCancel}
            buttonSize={"small"}></Button>
        </div>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <div className="userInfo">
          <h4>Email:</h4>
          <h4>{userInfo.userEmailId}</h4>
        </div>
        <div className="userInfo">
          <h4>Application Id:</h4>
          <h4>{userInfo.applicationId}</h4>
        </div>
        <h6>
          User Access Start DateTime and End DateTime are in UTC timezone.
        </h6>
        <div className="user-access">
          <div>
            <label>Requested By *</label>
            <input
              data-test-id={"requestedBy"}
              type="email"
              name="requestedBy"
              value={userInfo.requestedBy}
              onChange={handleChange}
              placeholder="Requester Email Id"
            ></input>
          </div>
          <div>
            <label>Access Start DateTime *</label>
            <input
              data-test-id={"accessStartDateTime"}
              type="datetime-local"
              name="accessStartDateTime"
              value={userInfo.accessStartDateTime}
              onChange={handleChange}
              min="2020-01-01T00:00:00"
              max="9999-12-31T00:00:00"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            ></input>
          </div>
          <div>
            <label>Access End DateTime (Optional)</label>
            <input
              data-test-id={"accessEndDateTime"}
              type="datetime-local"
              name="accessEndDateTime"
              value={userInfo.accessEndDateTime}
              onChange={handleChange}
              min={userInfo.accessStartDateTime}
              max="9999-12-31T00:00:00.000Z"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            ></input>
          </div>
        </div>
        {formErrors.formValid === false && (
          <div
            className="error"
            id="validationError"
            data-test-id="validationError"
          >
            <p>{formErrors.validationError.requestedByError}</p>
            <p>{formErrors.validationError.accessStartDateTimeError}</p>
            <p>{formErrors.validationError.accessEndDateTimeError}</p>
          </div>
        )}
      </form>
    </div>
  );
}
