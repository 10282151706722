import { Header } from "@medm-ui/header";
import "./header.scss";

export function AppHeader() {
  return (
    <Header userInfo={{
      name: "",
      lastLoginTime: ""
    }} userProfileMenuItems={[]} logOutButtonLabel={""} />
  );
}
