import { AdminUser } from "../../model/adminUser.model";
import { ActionTypes } from "../actionTypes";

const adminUser: AdminUser = {
  name: '',
  email: ''
}

const adminUserReducer = (
  state: any = adminUser,
  action: any
): any => {
  switch (action.type) {
    case ActionTypes.ADD_USER:
      return { ...state, name: action.payload.name, email: action.payload.email };
    case ActionTypes.REMOVE_USER:
      return 'userRemoved';
  }
  return state;
};

export default adminUserReducer;
