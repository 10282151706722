import { addUser } from "../state/action/adminUserAction";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { AdminUser } from "../model/adminUser.model";
const axios = require("axios");

const ValidateAccessToken = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    let url = window.location.hostname;
    let unionApiUrl = '';
    switch (url) {
      case 'unionadmin.qa.finapps.ihsmarkit.com':
        unionApiUrl = "https://api.qa.finapps.ihsmarkit.com";
        break;
      case 'unionadmin.uat.finapps.ihsmarkit.com':
        unionApiUrl = "https://api.uat.finapps.ihsmarkit.com";
        break;
      case 'unionadmin.finapps.ihsmarkit.com':
        unionApiUrl = "https://api.finapps.ihsmarkit.com";
        break;
      default:
        unionApiUrl = "https://api.dev.finapps.ihsmarkit.com";
        break;
    }
    isvalid(unionApiUrl);
  }, []);

  const isvalid = async (unionApiUrl: string) => {
    try {
      const response = await axios.get(unionApiUrl + "/User");
      const adminUser: AdminUser = {
        name: response.data.profile.name,
        email: response.data.profile.email
      }
      dispatch(addUser(adminUser));
    } catch (error) {
      return [];
    }
  };
};

export default ValidateAccessToken;
