import { AdminUser } from "../../model/adminUser.model";
import { ActionTypes } from "../actionTypes";

export const addUser = (adminUserInfo: AdminUser) => {
  return {
    type: ActionTypes.ADD_USER,
    payload: adminUserInfo,
  };
};

export const removeUser = (name: string) => {
  return {
    type: ActionTypes.REMOVE_USER,
    payload: name,
  };
};

