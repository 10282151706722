import axios from 'axios';
import CryptoES from 'crypto-es';

export function interceptor() {
    axios.interceptors.request.use(function (config: any) {
        const SamAccessToken = CryptoES.AES.decrypt(
            sessionStorage["union-access-token"],
            "union-access-token"
        )
            .toString(CryptoES.enc.Utf8)
            .replace(/^"(.*)"$/, "$1");

        config.headers.Authorization = SamAccessToken
            ? `Bearer ${SamAccessToken}`
            : "";

        config.headers["Content-Type"] = "application/json";

        return config;
    });
}